@import '~amo/css/styles';

html,
body {
  background: $header-base-color;
  height: 100%;
}

html {
  box-sizing: border-box;
}

input,
textarea {
  @include font-regular;

  // Fix search input and possibly other inputs.
  // See https://github.com/mozilla/addons-frontend/issues/3640

  background: $white;
  border: 1px solid $grey-50;
  border-radius: $border-radius-xs;
  box-shadow: none;
  color: $black;
  font-size: $font-size-default;
  padding: 4px;
}

*,
*::before,
*::after {
  box-sizing: inherit;

  // We use `anywhere` instead of `break-word` so that it works even when the
  // container does not have a well-defined width.
  overflow-wrap: anywhere;

  // This is needed for Safari, which does not support `anywhere`.
  word-break: break-word;
}

body {
  @include font-regular;

  color: $body-font-color;
  font-size: $font-size-default;
  line-height: $line-height-body;
}

#react-view {
  height: 100%;
}

h1,
h2 {
  @include font-bold;

  color: $primary-font-color;
  font-weight: 600;
  line-height: $line-height-compressed;
}

h1 {
  font-size: $font-size-heading-m;
}

h2 {
  font-size: $font-size-l;
}

h3 {
  @include font-bold;

  font-size: $font-size-default;
  line-height: $line-height-compressed;
}

a:link {
  @include font-medium;

  color: $link-color;
}

em {
  @include font-regular-italic;
}

strong {
  @include font-bold;
}

.caption {
  color: $primary-font-color;
  font-size: $font-size-xs;
}

.date-time {
  color: #b1b1b1;
  font-size: $font-size-default;
}

button {
  background: none;
  border: none;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
