@import '~amo/css/styles';

.FlagReview-button {
  padding: 0;
}

.FlagReview-button[disabled] {
  @include disabled;

  &:hover,
  &:focus,
  &:active {
    color: initial !important;
  }
}
