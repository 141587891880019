@import '~amo/css/styles';

.DropdownMenu {
  @include text-align-start;

  display: inline-block;
  height: 27px;
  padding-bottom: 2px;
  padding-top: 4px;
  position: relative;

  @include respond-to(medium) {
    height: auto;
    padding-bottom: 6px;
  }
}

.DropdownMenu-button {
  &:focus {
    // Fix outline for Firefox (see #3110)
    outline: 1px dotted $white;

    // Restore default outline for webkit.
    outline: auto 5px -webkit-focus-ring-color;
  }

  &:not(.focus-visible) {
    outline: none;
  }

  .DropdownMenu-button-text {
    @include font-regular;

    color: $white;
    display: inline-block;
    font-size: $font-size-s;
    font-weight: normal;
    max-width: 100px;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include respond-to(medium) {
      max-width: 200px;
    }

    @include respond-to(extraLarge) {
      max-width: 300px;
    }
  }

  .Icon-inverted-caret {
    @include margin-start(5px);

    display: inline-block;
    opacity: 0.6;
    transition: opacity $transition-short ease-in-out;
    width: 9px;

    .DropdownMenu--active & {
      opacity: 1;
    }
  }
}

.DropdownMenu-items {
  @include font-regular;
  @include end(0);

  background: $white;
  border: 0;
  border-radius: $border-radius-default;
  box-shadow: 0 0 2px transparentize($black, 0.5);
  display: none;
  list-style-type: none;
  margin: 0;
  max-width: 245px;
  padding: 4px 24px 20px;
  position: absolute;
  top: 25px;
  white-space: nowrap;
  width: auto;
  z-index: 10;

  // This is the triangle at the top of the dropdown menu which is
  // actually just a tilted square.
  &::after {
    @include end(36px);

    background-color: $white;
    content: '';
    display: block;
    height: 8px;
    position: absolute;
    top: -4px;
    transform: rotate(45deg);
    width: 8px;
  }

  .DropdownMenu--active & {
    display: block;
  }

  @include respond-to(extraLarge) {
    max-width: 300px;
  }
}
