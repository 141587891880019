@import '~amo/css/styles';

.AddonInfo {
  @include page-padding;

  @include respond-to(large) {
    display: flex;
  }
}

.AddonInfo-info {
  margin-top: $padding-page;

  @include respond-to(large) {
    @include margin-start($padding-page-l);

    margin-top: 0;
    width: 65%;
  }
}

.AddonMoreInfo-related-categories-list,
.AddonMoreInfo-tag-links-list {
  li {
    display: inline-block;
  }

  li::after {
    content: '\00a0\2219\00a0';
  }

  li:last-child::after {
    content: '';
  }
}
