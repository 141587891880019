@import '~amo/css/styles';

.SecondaryHero {
  color: $black;
  display: grid;
  grid-gap: $padding-page;
  grid-template-columns: auto;
  min-height: 568px;
  padding: $padding-page 0 0 0;

  @include respond-to(medium) {
    min-height: 628px;
  }

  @include respond-to(large) {
    grid-gap: 32px;
    grid-template-columns: repeat(4, 1fr);
    min-height: 340px;
    padding: 48px 0 24px;
  }

  @include respond-to(extraLarge) {
    min-height: 296px;
  }
}

.SecondaryHero-message {
  @include font-regular;

  font-size: $font-size-l;
  margin-top: 0;
  padding: 0 10px 10px;

  @include respond-to(large) {
    padding: 0;
  }
}

.SecondaryHero-message-headline {
  @include font-medium;

  font-size: $font-size-heading-xs;
  line-height: $line-height-compressed;
  margin: 0;

  .LoadingText {
    height: 1em;
  }
}

.SecondaryHero-message-description {
  padding: $padding-page 0;

  @include respond-to(large) {
    padding: 8px 0 24px;
  }

  .LoadingText {
    height: 1em;
  }
}

.SecondaryHero-module {
  align-items: center;
  background-color: $white;
  border-radius: $border-radius-s;
  display: flex;
  flex-direction: column;
  font-size: $font-size-l;
  padding: $padding-page 0;
  padding-top: 0;

  @include respond-to(large) {
    padding: 0 24px 24px;
  }
}

.SecondaryHero-module-icon {
  height: 60px;
  margin: $padding-page auto;
  width: 60px;

  @include respond-to(large) {
    margin: 30px auto;
  }
}

.SecondaryHero-module-link {
  margin-top: 6px;
}

.SecondaryHero-message-link,
.SecondaryHero-module-link {
  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: $link-color;
    font-weight: normal;
    text-decoration: none;
  }
}

.SecondaryHero-message-link,
.SecondaryHero-module-description,
.SecondaryHero-module-link {
  width: 100%;

  .LoadingText {
    height: 1em;
  }
}

.SecondaryHero-module-description,
.SecondaryHero-module-link {
  text-align: center;
}
