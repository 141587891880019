@import '~amo/css/styles';

.CollectionControls {
  margin-top: $padding-page;

  @include respond-to(large) {
    margin-top: $padding-page-l;
  }

  .Card-contents {
    overflow: visible;
  }
}
