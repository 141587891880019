@import '~amo/css/styles';

.ReportAbuseButton {
  margin: 12px auto 0;
}

.ReportAbuseButton-header {
  margin-bottom: 0;
}

.ReportAbuseButton-first-paragraph {
  margin-top: 6px;
}

.ReportAbuseButton--preview {
  display: inline-block;
  width: 100%;
}

.ReportAbuseButton-show-more {
  width: 100%;
}
