@import '~amo/css/styles';

.SectionLinks {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 0 4px;
}

.SectionLinks-item {
  display: inline-block;
}

.SectionLinks-link,
.SectionLinks-link:link {
  color: $grey-40;
  display: inline-block;
  font-size: $font-size-default;
  font-weight: 400;
  margin: 0 8px;
  outline: none;

  // This is required to deal with the section links wrapping at a smaller
  // screen resolution.
  overflow-wrap: break-word;
  padding: 0 0 2px;
  text-decoration: none;
  word-break: initial;

  @include respond-to(large) {
    margin: 0 12px;
  }

  &::after {
    background: transparent;
    content: '';
    display: block;
    height: 3px;
    margin: auto;
    transition: width $transition-short ease,
      background-color $transition-short ease;
    width: 0;
  }

  &:focus,
  &:hover {
    &::after {
      background: $grey-40;
      width: 100%;
    }
  }

  &:active {
    &::after {
      background-color: $white;
    }
  }
}

.SectionLinks-link--active,
.SectionLinks-link--active:link {
  color: $white;

  &,
  &:active,
  &:focus,
  &:hover {
    &::after {
      background-color: $white;
      width: 100%;
    }
  }
}

.SectionLinks-explore,
.SectionLinks-explore:link {
  display: none;

  @include respond-to(large) {
    display: inline-block;
  }
}

.SectionLinks-dropdown {
  padding: 0;
  white-space: nowrap;

  &,
  &:active,
  &:focus,
  &:hover {
    &::after {
      width: 0;
    }
  }

  .DropdownMenu-button {
    cursor: pointer;
    margin-top: 2px;
    padding: 0 0 2px;
  }

  .DropdownMenu-button-text {
    color: $grey-40;
    font-size: $font-size-default;
  }
}
