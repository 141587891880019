@import '~amo/css/styles';

.PermissionsCard-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.PermissionsCard-learn-more {
  .Icon {
    @include margin-start(5px);
  }
}

.PermissionsCard-subhead--optional,
.PermissionsCard-subhead--required {
  color: $grey-50;
  margin: 0;
}

.PermissionsCard-list--optional,
.PermissionsCard-list--required {
  list-style: none;
  padding: 0;

  li {
    align-items: center;
    display: flex;
    margin-top: 16px;
    vertical-align: middle;

    .Icon {
      @include margin-end(12px);

      height: 24px;
      min-width: 24px;
    }
  }
}
