@import '~amo/css/styles';

.SearchContextCard-header {
  font-size: $font-size-l;
  margin: 0;

  @include respond-to(large) {
    font-size: $font-size-heading-s;
  }
}
