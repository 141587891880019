@import '~amo/css/styles';

@keyframes place-holder-shimmer {
  0% {
    opacity: 0.5;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.75;
  }

  75% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.5;
  }
}

.LoadingText {
  animation: place-holder-shimmer 3s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
  background: $grey-30;
  display: inline-block;
  height: 1rem;
  margin: 0;
  vertical-align: middle;
}

@for $delay from 1 through 3 {
  // stylelint-disable scss/operator-no-newline-after
  .LoadingText--delay-#{$delay} {
    animation: place-holder-shimmer
      #{$delay *
      1.5}s
      infinite
      cubic-bezier(0.65, 0.05, 0.36, 1);

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }
}

$widths: (
  20: 20%,
  40: 40%,
  60: 60%,
  80: 80%,
  100: 100%,
);

@each $className, $width in $widths {
  .LoadingText--width-#{$className} {
    width: $width;
  }
}
