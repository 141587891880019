@import '~amo/css/styles';
@import '~amo/components/IconPromotedBadge/styles';

.PromotedBadge {
  align-items: flex-start;
  display: flex;
  font-size: $font-size-xs;

  &.PromotedBadge-large {
    font-size: $font-size-default;
    margin-bottom: 6px;
  }

  .Addon-theme & {
    @include margin-end(12px);
  }
  @include respond-to(large) {
    justify-content: flex-end;

    .Addon-theme & {
      justify-content: flex-start;
    }
  }
}

.PromotedBadge--line {
  color: $grey-30;
}

.PromotedBadge--recommended {
  color: $orange-50;
}

.PromotedBadge-link {
  align-items: center;
  border: 1px solid $color-light-gray-40;
  border-radius: 20px;
  box-sizing: border-box;
  color: $color-dark-gray-05;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 3px;

  &,
  &:link,
  &:visited {
    color: $color-dark-gray-05;
    font-weight: normal;
    text-decoration: none;
  }

  &:active,
  &:focus,
  &:hover {
    border-color: $color-light-gray-90;
    color: $color-dark-gray-05;
  }
}

.PromotedBadge-label {
  @include margin-start(6px);
  @include margin-end(6px);
}

.PromotedBadge-small .PromotedBadge-label {
  @include margin-end(4px);
}
