@import '~amo/css/styles';

.AddonReviewManager {
  font-size: $font-size-default;

  .DismissibleTextForm-formFooter {
    font-size: $font-size-s;
    margin: 0;
    text-align: center;

    a {
      font-weight: normal;

      &,
      &:link,
      &:visited {
        color: $black;
      }

      &:active,
      &:hover {
        color: $link-color;
      }
    }
  }

  .Rating {
    width: 100%;
  }

  @include respond-to(extraExtraLarge) {
    .Rating {
      width: inherit;
    }
  }
}

.AddonReviewManager-savedRating {
  @include margin-start(6px);

  color: $grey-50;
  flex-grow: 1;
  opacity: 1;
  text-align: right;

  [dir='rtl'] & {
    text-align: left;
  }
}
