@import '~amo/css/styles';

.AddonTitle {
  @include font-regular;

  color: $black;
  font-size: $font-size-heading-s;
  grid-column: 1 / span 2;
  margin: 0 0 $padding-page-l;
  width: 100%;

  @include respond-to(medium) {
    font-size: $font-size-heading-m;
  }

  .AddonTitle-author {
    @include font-light;

    display: block;
    padding-top: 3px;

    &,
    a,
    a:link {
      font-size: $font-size-l;
      font-weight: normal;
      text-decoration: none;

      @include respond-to(medium) {
        font-size: $font-size-heading-s;
      }
    }
  }
}
