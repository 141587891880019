@import '~amo/css/styles';

.SignedInUser {
  display: inline-flex;
  align-items: center;
}

.SignedInUser-text {
  @include margin-start(4px);
}

.SignedInUser--disabled {
  @include disabled;
}
