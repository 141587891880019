@import '~amo/css/styles';

.Addon {
  @include page-padding;

  .Addon-summary-and-install-button-wrapper {
    .AMInstallButton {
      @include respond-to(medium) {
        align-self: flex-end;
      }
    }

    .AMInstallButton--noDownloadLink {
      @include respond-to(medium) {
        margin-bottom: 12px;
      }
    }
  }
}

.Addon .Card {
  margin-bottom: $padding-page;

  @include respond-to(large) {
    margin-bottom: $padding-page-l;
  }
}

.Addon-icon {
  min-height: 72px;

  @include respond-to(medium) {
    min-height: 96px;
  }
}

.Addon-icon-wrapper {
  height: 48px;
  overflow: hidden;
  width: 48px;

  @include respond-to(medium) {
    height: 64px;
    overflow: hidden;
    width: 64px;
  }
}

.Addon-icon-image {
  height: auto;
  width: 100%;
}

.Addon-header-wrapper {
  @include respond-to(large) {
    display: flex;
  }
}

.Addon-header-info-card {
  @include respond-to(large) {
    @include margin-end($padding-page-l);

    width: 65%;
  }

  .AddonCompatibilityError,
  .Addon-non-public-notice {
    margin: 0;
    margin-bottom: 12px;
  }
}

.Addon-header-meta-and-ratings {
  @include respond-to(large) {
    min-width: 300px;
    width: 35%;
  }
}

.Addon-theme .AddonTitle {
  margin: $padding-page-l 0;
}

.Addon-header {
  display: grid;
  grid-template-columns: 1fr;
}

.Addon-summary-and-install-button-wrapper {
  grid-column: 1 / span 2;
  width: 100%;

  .Button {
    text-align: center;
    white-space: normal;

    @include respond-to(medium) {
      white-space: nowrap;
    }
  }

  @include respond-to(extraLarge) {
    display: flex;
    justify-content: space-between;
  }
}

.Addon-summary {
  font-size: $font-size-default;
  overflow-x: auto;

  @include respond-to(medium) {
    @include margin-end(24px);

    max-width: 550px;
  }
}

.Addon .AMInstallButton {
  @include respond-to(medium) {
    @include margin-start(auto);

    align-content: center;
    align-self: center;
  }
}

.Addon .AMInstallButton-button {
  width: 100%;

  @include respond-to(medium) {
    width: auto;
  }
}

.Addon .AMInstallButton-loading-button {
  @include respond-to(medium) {
    width: 48px;
  }
}

// Details section with lots of grid stuff, on larger displays.
@include respond-to(large) {
  .Addon-details {
    display: grid;
    grid-auto-flow: column dense;
    grid-gap: 0 $padding-page-l;
    grid-template-columns: minmax(300px, 35%) auto;
    margin: 0;

    .Addon-main-content {
      grid-column: 2;

      // This span makes sure the left widget does not move vertically when
      // there is a long description.

      grid-row: 1 / span 100000;

      // Fixes screenshot overflow in FF 52 ESR.
      max-width: 100%;
      overflow-x: hidden;

      .Card:first-child {
        margin-top: 0;
      }
    }

    .AddonDescription-contents,
    .Addon-developer-comments-contents {
      max-width: 550px;
    }
  }

  .Addon-screenshots {
    // overflow required to fix content overlap in Safari.
    // See https://github.com/mozilla/addons-frontend/issues/2847

    overflow-x: hidden;
  }

  // We hide this "Read reviews" link on larger displays as we actually show
  // the first page of reviews on the page.
  // TODO: Enable this once we load reviews on the addon page.
  // See: https://github.com/mozilla/addons-frontend/issues/2552
  // .Addon-overall-rating .Card-footer-link {
  //   display: none;
  // }
}

.Addon-WrongPlatformWarning {
  margin: 12px 0 0;
  padding-left: 0;
  padding-right: 0;

  @include respond-to(medium) {
    margin-top: 0;
  }
}
