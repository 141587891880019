@import '~amo/css/styles';

.CategoriesPage {
  @include page-padding;

  .Categories-item {
    display: block;
  }

  .Categories-loading-text {
    display: block;
    margin: 0 0 5px;
  }
}
