@import '~amo/css/styles';

.StaticPage {
  @include page-padding;

  .Card-contents li,
  .Card-contents p {
    font-size: $font-size-default;
  }

  h1,
  h2 {
    color: $grey-90;
  }

  h1 {
    font-size: $font-size-l;
  }

  h2,
  h3 {
    font-size: $font-size-default;
  }

  h3 {
    color: $grey-60;
  }
}

.StaticPage-content {
  max-width: 960px;
}
