@import '~amo/css/styles';

.Categories .Card-contents {
  @include respond-to(large) {
    padding-bottom: 12px;
  }
}

.Categories-loadingText {
  @include margin-end(5px);
}

.Categories-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0 auto;
  padding: 0;
}

.Categories-item {
  $category-gutter: 7px;

  @include respond-to(large) {
    $category-gutter: 12px;
  }

  @include margin-end($category-gutter);

  display: inline-block;
  margin: 0 0 $category-gutter;
  padding: 0;
}

.Categories-link,
.Categories-link:link,
.Categories-link:visited,
.Categories-link:hover,
.Categories-link:active {
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: $font-size-s;
  font-weight: normal;
  padding: 10px 12px 8px;
  text-decoration: none;
}

// There are currently 12 colors and this number must be kept in sync with
// `$category-colors` in `src/amo/css/inc/vars.scss`.

@for $i from 1 through 12 {
  .Categories--category-color-#{$i} {
    background: nth($category-colors, $i);

    &:focus,
    &:hover {
      background: darken(nth($category-colors, $i), 20);
    }
  }
}
