@import '~amo/css/styles';

.AppBanner {
  padding: $padding-page;

  // When the div contains no child
  &:empty {
    padding: 0;
    padding-bottom: $padding-page;
  }

  & > *:not(:last-child) {
    margin-bottom: $padding-page / 2;
  }

  @include respond-to(large) {
    padding: $padding-page-l;
    padding-bottom: $padding-page-l / 2;
    padding-top: $padding-page-l / 2;

    & > *:not(:last-child) {
      margin-bottom: $padding-page-l / 2;
    }
  }
}
