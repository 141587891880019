@import '~amo/css/styles';

$avatar-size: 64px;

.UserAvatar-image {
  height: auto;
  width: 100%;
}

.UserProfile {
  @include page-padding;
}

.UserProfile-wrapper {
  @include respond-to(large) {
    display: flex;
    justify-content: flex-start;
  }
}

.UserProfile-user-info {
  @include respond-to(large) {
    min-width: 300px;
    width: 35%;
  }
}

.UserProfile-header {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.UserProfile-avatar {
  height: $avatar-size;
  margin: 0 0 12px;
  overflow: hidden;
  width: $avatar-size;
}

.UserProfile-avatar .Icon {
  height: $avatar-size;
  width: $avatar-size;
}

.UserProfile-tags {
  @include font-regular;
  @include text-align-end;

  .UserProfile-artist,
  .UserProfile-developer {
    margin-bottom: 8px;
    margin-top: 0;
  }

  .Icon {
    @include margin-start(6px);

    margin-bottom: -2px;
  }
}

.UserProfile-name {
  @include font-regular;

  font-size: $font-size-heading-s;
  grid-column: 1 / span 3;
  margin: 0;
  word-break: break-all;

  @include respond-to(medium) {
    font-size: $font-size-heading-m;
  }
}

.UserProfile-rating-average .Rating {
  height: $font-size-l;
  justify-content: start;
}

.UserProfile-abuse-button {
  margin-top: 24px;

  .ReportUserAbuse-show-more {
    width: 100%;
  }
}

.UserProfile-admin-link,
.UserProfile-edit-link {
  margin-top: $padding-page;
  width: 100%;
}

.UserProfile-addons-and-reviews {
  @include margin(12px, 0, 0, 0);

  width: 100%;

  @include respond-to(large) {
    @include margin(0, 0, 0, 12px);

    max-width: calc(100% - 300px - 12px);
  }

  @include respond-to(extraLarge) {
    max-width: calc(65% - 12px);
  }
}

.UserProfile-biography {
  & > p {
    margin-top: 0;
  }
}

.UserProfile-reviews {
  margin-top: 0;
}
