@import '~amo/css/styles';

.Badge {
  color: $grey-90;
  font-size: $font-size-default;
  line-height: $line-height-compressed;
  margin: 0 0 6px;

  @include respond-to(large) {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 0 6px;

    .Addon-theme & {
      flex-direction: row;
    }
  }

  .Icon {
    @include margin(2px, 6px, 0, 0);

    @include respond-to(large) {
      @include margin(2px, 0, 0, 6px);
    }

    vertical-align: top;
  }
}
