@import '~amo/css/styles';

.HeroRecommendation-wrapper {
  margin: 0 auto;
  max-width: $max-content-width;
}

.HeroRecommendation--height-with-notice {
  min-height: 328px;

  @include respond-to(extraExtraLarge) {
    min-height: 452px;
  }
}

.HeroRecommendation--height-without-notice {
  min-height: 252px;

  @include respond-to(extraExtraLarge) {
    min-height: 376px;
  }
}

.HeroRecommendation .ErrorList {
  padding: 24px;
  padding-bottom: 0;
}

.HeroRecommendation-banner {
  padding-bottom: 0;
}

.HeroRecommendation-content {
  color: $white;
  display: flex;
  min-width: 0;
  padding: 24px;

  @include respond-to(extraExtraLarge) {
    padding: 36px;
  }
}

.HeroRecommendation-image-loading {
  @include respond-to(medium) {
    width: 40%;
  }

  .LoadingText {
    height: 100%;
  }
}

.HeroRecommendation-image-wrapper {
  @include respond-to(medium) {
    flex: 40%;
  }

  @include respond-to(extraExtraLarge) {
    flex: initial;
  }
}

.HeroRecommendation-info {
  display: flex;
  flex-direction: column;
  line-height: $line-height-compressed;
  min-width: 300px;

  @include respond-to(medium) {
    @include margin-start(24px);

    flex: 60%;
  }

  @include respond-to(extraLarge) {
    @include margin-start(96px);
  }

  @include respond-to(extraExtraLarge) {
    @include margin-start(132px);

    flex: initial;
  }
}

.HeroRecommendation-title-text {
  @include font-medium;

  display: inline;
  font-size: $font-size-s;
  letter-spacing: 0.1em;
  margin: 0;
  opacity: 0.5;

  @include respond-to(medium) {
    font-size: $font-size-l;
  }

  @include respond-to(extraExtraLarge) {
    margin-top: 16px;
  }
}

.HeroRecommendation-title-link {
  font-size: $font-size-xs;
  @include margin-start(12px);

  &,
  &:active,
  &:hover,
  &:link,
  &:visited {
    color: $white;
    font-weight: normal;
    opacity: 0.5;
    text-decoration: none;
  }
}

.HeroRecommendation-body,
.HeroRecommendation-heading,
.HeroRecommendation-title {
  width: 100%;

  .LoadingText {
    height: 1em;
  }
}

.HeroRecommendation-heading {
  @include font-medium;

  color: $white;
  font-size: $font-size-heading-s;
  margin: 14px 0 0;

  @include respond-to(extraExtraLarge) {
    font-size: $font-size-heading-m;
  }
}

.HeroRecommendation-body {
  @include font-light;

  font-size: $font-size-s;
  line-height: $line-height-body;
  margin: 18px 0 24px;

  @include respond-to(medium) {
    font-size: $font-size-heading-xs;
  }

  @include respond-to(extraExtraLarge) {
    margin-bottom: 84px;
  }
}

.HeroRecommendation--no-image {
  justify-content: center;

  .HeroRecommendation-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 auto;

    @include respond-to(large) {
      flex-grow: 0;
      width: 576px;
    }
  }

  .HeroRecommendation-title,
  .HeroRecommendation-heading,
  .HeroRecommendation-body,
  .HeroRecommendation-link {
    text-align: center;
  }
}

.HeroRecommendation-image {
  height: 0;
  visibility: hidden;
  width: 0;

  @include respond-to(medium) {
    height: auto;
    visibility: visible;
    width: 100%;
  }

  @include respond-to(extraExtraLarge) {
    width: 480px;
  }
}

.HeroRecommendation-link {
  background-color: transparent;
  border: 4px solid $white;
  display: inline-block;
  font-size: $font-size-l;
  padding: 12px 24px;
  text-align: center;

  // The theme styles will add a hover effect.
  transition: background-color $transition-medium ease-in-out;
  white-space: nowrap;
  width: fit-content;

  &,
  &:active,
  &:link,
  &:hover,
  &:visited {
    color: $white;
    text-decoration: none;
  }

  @include respond-to(medium) {
    padding: 12px 48px;
  }
}

@mixin gradient-style($startColor, $stopColor) {
  background-image: linear-gradient($startColor, $stopColor);

  .HeroRecommendation-link {
    &:hover,
    &:active {
      background-color: $stopColor;
    }
  }
}

// Styles that correspond to gradients returned by the hero API
.HeroRecommendation-color-ink-80-color-blue-70 {
  @include gradient-style($color-ink-80, $color-blue-70);
}

.HeroRecommendation-color-ink-80-color-green-70 {
  @include gradient-style($color-ink-80, $color-green-70);
}

.HeroRecommendation-color-ink-80-color-pink-70 {
  @include gradient-style($color-ink-80, $color-pink-70);
}

.HeroRecommendation-color-ink-80-color-purple-70 {
  @include gradient-style($color-ink-80, $color-purple-70);
}

.HeroRecommendation-color-ink-80-color-violet-70 {
  @include gradient-style($color-ink-80, $color-violet-70);
}

.HeroRecommendation--loading {
  @include gradient-style($color-ink-80, $color-blue-70);
}

.HeroRecommendation-WrongPlatformWarning {
  margin-top: 12px;
}
