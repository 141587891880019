@import '~amo/css/styles';

$footer-padding: 10px;

.Card-header {
  @include addon-section;
  @include font-bold;
  @include text-align-start;

  border-top-left-radius: $border-radius-default;
  border-top-right-radius: $border-radius-default;
  font-size: $font-size-default;
  margin-bottom: 1px;
  margin-top: 0;
}

.Card-contents {
  @include addon-section;

  font-size: $font-size-s;
  margin: 0;

  .Card--no-header & {
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
  }

  .Card--no-footer & {
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
  }

  .Card--photon & {
    padding: 20px 15px;
  }

  &,
  .Card--photon & {
    @include respond-to(large) {
      padding: $padding-page-l 36px;
    }
  }
}

.Card-footer {
  @include addon-section;

  border-bottom-left-radius: $border-radius-default;
  border-bottom-right-radius: $border-radius-default;
  margin-top: 1px;
  overflow: inherit;
  padding: 0;
  text-align: center;
}

.Card-footer-link,
.Card-footer-text,
.Card-shelf-footer-in-header {
  a,
  a:active,
  a:hover,
  a:link,
  a:visited {
    color: $link-color;
    display: block;
    font-weight: normal;
    padding: $footer-padding;
    text-decoration: none;
  }

  a:focus {
    outline: 1px dotted $link-color;
    outline: auto 5px -webkit-focus-ring-color;
  }
}

.Card-footer-text {
  padding: $footer-padding;

  @include respond-to(large) {
    padding: 15px;
  }
}

.Card-footer-link {
  @include respond-to(large) {
    padding: 5px 26px;
  }
}

.Card-shelf-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Card-shelf-footer {
  @include respond-to(large) {
    display: none;
  }
}

.Card-shelf-footer-in-header {
  @include respond-to(mediumOnly) {
    display: none;
  }

  margin-left: 16px;
  padding: 0;
  text-align: right;

  a,
  a:active,
  a:hover,
  a:link,
  a:visited {
    padding: 0;
  }
}
