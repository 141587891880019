@import '~amo/css/styles';

.LandingPage {
  @include page-padding;
}

.LandingPage-header {
  background-color: $white;
  border-radius: $border-radius-default;
  margin-bottom: 12px;
  padding: 24px;

  @include respond-to(medium) {
    padding: 48px 36px;
  }

  @include respond-to(large) {
    margin-bottom: 24px;
  }
}

.LandingPage-button {
  width: 100%;

  @include respond-to(medium) {
    display: none;
  }
}

.LandingPage-addonType-name {
  @include font-bold;

  font-size: $font-size-heading-s;
  margin: 0 0 6px;

  @include respond-to(medium) {
    font-size: $font-size-heading-m;
    margin: 0 0 12px;
  }
}

.LandingPage-heading-content {
  @include font-regular;

  font-size: $font-size-s;
  margin: 0;
  max-width: 600px;

  @include respond-to(medium) {
    font-size: $font-size-l;
  }
}

.LandingPage .Categories {
  display: none;

  @include respond-to(medium) {
    display: block;
  }
}
