@import '~amo/css/styles';

.Header {
  background: $color-ink-80;
}

.Header-wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto) min-content;
  min-height: 112px;
  padding: 0 12px;

  @include respond-to(medium) {
    grid-template-columns: 1fr;
    min-height: 98px;
    padding: 0 $header-footer-gutter 10px;
  }

  @include respond-to(extraLarge) {
    grid-template-columns: max-content 1fr 1fr;
    grid-template-rows: 46px auto;
    margin: 0 auto;
    max-width: $max-content-width;
    min-height: 112px;
    padding-bottom: 20px;
    width: 100%;
  }
}

.Header-content {
  grid-column: 1 / span 4;
  grid-row: 2 / 4;
  margin: 0 0 36px;
  text-align: center;
  width: 100%;
  z-index: 4;

  .Header--loaded-page-is-anonymous & {
    margin-top: 39px;
  }

  @include respond-to(medium) {
    @include text-align-start;

    align-self: end;
    grid-row: 1 / 2;
    margin: 48px 0 0;
    width: 50%;

    &,
    .Header--loaded-page-is-anonymous & {
      margin: 48px 0 0;
    }
  }

  @include respond-to(extraLarge) {
    @include margin-end(24px);

    align-self: center;
    grid-column: 1;
    grid-row: 2 / 2;

    &,
    .Header--loaded-page-is-anonymous & {
      margin-top: 0;
    }
  }
}

.Header-title-wrapper {
  margin: 0;
}

.Header-title {
  &,
  &:link {
    color: $white;
  }

  // This is width / height of the source SVG.
  $aspectRatio: 4.3125;
  $smallHeight: 48px;
  $smallWidth: $smallHeight * $aspectRatio;
  $largeHeight: 64px;
  $largeWidth: $largeHeight * $aspectRatio;

  background-image: url('./img/firefox-lockup-horizontal-white.svg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: $smallWidth $smallHeight;
  display: block;
  height: $smallHeight;
  margin: 0;
  width: 100%;

  @include respond-to(medium) {
    background-position: left bottom;
    width: $smallWidth;
  }

  @include respond-to(extraExtraLarge) {
    background-size: $largeWidth $largeHeight;
    height: $largeHeight;
    width: $largeWidth;
  }
}

.Header-user-and-external-links {
  grid-column: 4;
  grid-row: 1 / 2;
  margin-top: 10px;
  text-align: center;

  @include respond-to(medium) {
    @include text-align-end;

    grid-column: 4 / -1;
    grid-row: 1 / 2;
  }

  @include respond-to(extraLarge) {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  .Header-blog-link {
    &:hover {
      text-decoration: underline;
    }
  }
}

.Header-button {
  @include margin-start(20px);

  margin-bottom: 12px;
  vertical-align: top;
  white-space: nowrap;
}

.Header-blog-link,
.Header-developer-hub-link,
.Header-extension-workshop-link {
  display: none;
}

.Header-authenticate-button,
.Header-blog-link,
.Header-developer-hub-link,
.Header-extension-workshop-link {
  &,
  &:link {
    color: $white;
    font-size: $font-size-s;
    font-weight: normal;
    padding-top: 4px;
    text-decoration: none;
    vertical-align: baseline;
  }

  @include respond-to(large) {
    display: inline-block;
  }
}

.Header-authenticate-button {
  @include margin-start(6px);

  display: inline-flex;
  min-height: 24px;
  white-space: nowrap;

  &,
  &:link {
    font-size: $font-size-s;
  }

  .DropdownMenu-button {
    display: flex;
    padding: 0;
  }

  @include respond-to(medium) {
    @include margin-start(20px);

    display: initial;

    &.DropdownMenu {
      display: inline-flex;
    }
  }
}

.Header-SectionLinks {
  align-items: baseline;
  grid-column: 1 / span 4;
  grid-row: 3 / 4;
  margin: 16px auto 1px;
  min-height: 28px;
  z-index: 4;

  .DropdownMenu-button-text {
    overflow: visible;
    padding-bottom: 3px;
  }

  .Icon-inverted-caret {
    height: 9px;
  }

  @include respond-to(medium) {
    @include margin-start(-12px);

    align-self: end;
    grid-column: 1 / 3;
    grid-row: 2 / 2;
  }

  @include respond-to(extraLarge) {
    @include margin-end(12px);

    align-self: center;
    grid-column: 2;
    margin: 22px 0 0;
    padding: 0;
  }
}

.Header-search-form {
  grid-column: 1 / span 3;
  grid-row: 1 / 3;
  justify-self: end;
  margin: 10px 0 0;
  width: 100%;

  @include respond-to(medium) {
    grid-column: 3 / 3;
    grid-row: 2 / 2;
    margin-top: 6px;
    max-width: 250px;
  }

  @include respond-to(extraLarge) {
    align-self: center;
    margin: 0;
    max-width: 284px;
    width: 100%;
  }

  &.Header-search-form--desktop {
    @include respond-to(medium) {
      max-width: 140px;
    }

    @include respond-to(large) {
      max-width: 250px;
    }

    @include respond-to(extraLarge) {
      max-width: 284px;
    }
  }
}
