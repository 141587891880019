@import '~amo/css/styles';

.AddonReviewList {
  @include page-padding;

  @include respond-to(large) {
    display: flex;
  }

  .Card {
    margin-bottom: $padding-page;

    @include respond-to(large) {
      margin-bottom: $padding-page-l;
    }
  }

  .CardList {
    margin: 0 0 8px;

    // https://stackoverflow.com/questions/36150458
    min-width: 50%;
  }
}

.AddonReviewList-reviews {
  @include respond-to(large) {
    @include margin-start($padding-page-l);

    width: 65%;
  }
}

.AddonReviewList-noReviews {
  padding: 48px 0;
  text-align: center;
}

.AddonReviewList-filterByScoreSelector {
  font-size: $font-size-s;
  width: auto;
}

.AddonReviewList-cardListHeader {
  display: flex;
  flex-wrap: wrap;
}

.AddonReviewList-reviewCount {
  // This is necessary to show LoadingText.
  flex-grow: 1;

  // This creates vertical space when items are wrapped on very
  // small-width screens.
  margin: 6px auto;

  @include margin-start(0);

  @include respond-to(medium) {
    margin: auto;

    @include margin-start(0);
  }
}

.AddonReviewList-filterByScore {
  margin: auto;

  @include margin-end(0);
}
