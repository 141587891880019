@import '~amo/css/styles';

.AddonsCard--horizontal {
  @include respond-to(large) {
    position: relative;

    .Card-contents {
      background: $white;
      border-bottom-left-radius: $border-radius-default;
      border-bottom-right-radius: $border-radius-default;
      padding: 12px 6px;
    }

    .Card-footer-text,
    .Card-footer-link {
      @include end(0);
      @include text-align-end;

      background: none;
      border: 0;
      position: absolute;
      top: 0;
    }

    ul.AddonsCard-list {
      display: grid;
      grid-auto-flow: column dense;
      grid-template-columns: repeat(4, 25%);

      .SearchResult {
        grid-column: auto;
        margin: 0 6px 1px;

        // Prevents content overflow.
        min-width: 0;
        padding: 0;
      }

      .SearchResult-wrapper {
        display: inline-block;
        height: 100%;
        padding: 12px 24px;
        transition: background-color $transition-short ease-in-out;
        width: 100%;
      }

      .SearchResult-result {
        display: grid;
        grid-column-gap: 8px;
        grid-template-columns: 32px auto;
      }

      .SearchResult-icon-wrapper {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }

      .SearchResult-contents {
        grid-column: 2 / 2;
        grid-row: 1 / 2;
        margin: 0;

        // Prevents content overflow.
        min-width: 0;
        width: auto;
      }

      .SearchResult-metadata,
      .SearchResult-metadata .SearchResult-author,
      .SearchResult-summary {
        display: none;
      }

      .SearchResult-users {
        @include margin-start(-3.5px);

        grid-column: 2 / 2;
        grid-row: 2 / 2;
      }

      .SearchResult-users,
      .SearchResult-metadata {
        height: 24px;
      }

      .SearchResult--theme {
        // stylelint-disable max-nesting-depth
        .SearchResult-result {
          display: block;
        }

        .SearchResult-icon-wrapper {
          border-radius: $border-radius-default;
          margin: 0 0 5px;
          width: 100%;
        }
        // stylelint-enable max-nesting-depth
      }

      .SearchResult-wrapper:focus,
      .SearchResult-wrapper:hover {
        background-color: $grey-10;
        border-radius: $border-radius-default;

        // stylelint-disable max-nesting-depth
        .SearchResult-users {
          display: none;
        }

        .SearchResult-metadata {
          display: block;
        }
        // stylelint-enable max-nesting-depth
      }
    }
  }
}
