@import '~amo/css/styles';

.InstallWarning {
  grid-column: 1 / span 2;
  margin: 8px auto;
  width: 100%;

  @include respond-to(medium) {
    margin: 0 auto 8px;
  }
}
