@import '~amo/css/styles';

.RatingManager-ratingControl {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.RatingManager-log-in-to-rate-button {
  font-size: $font-size-s;
  left: 50%;
  padding: 2px 16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.RatingManager {
  fieldset {
    // Prevent content overflow.
    min-width: 0;
  }

  .ShowMoreCard-contents::after {
    background: linear-gradient(
      rgba(255, 255, 255, 0%),
      $showmorecard-gradient-color
    );
  }
}

.RatingManager-UserRating {
  margin: 12px;
}

.RatingManager-savedRating-withReview {
  margin-bottom: 12px;
}

.RatingManager-AddonReviewManagerRating {
  margin: 12px 0;
  width: 100%;
}
