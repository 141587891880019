@import '~amo/css/styles';

.ConfirmationDialog-buttons {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .Button {
    margin-top: $padding-page;
  }

  @include respond-to(medium) {
    flex-flow: row-reverse;
  }
}
