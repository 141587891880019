@import '~amo/css/styles';

.CardList ul > li.UserCollection {
  padding: 4px $padding-page-l;

  @include respond-to(large) {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.UserCollection {
  grid-column: auto;
  list-style-type: none;
  word-break: break-all;
}

.UserCollection-link {
  text-decoration: none;
}

.UserCollection-name {
  @include font-bold;

  color: $black;
  flex-grow: 1;
  font-size: $font-size-default;
  margin: 0;
  width: 100%;

  .UserCollection-link:active &,
  .UserCollection-link:focus &,
  .UserCollection-link:hover & {
    color: $link-color;
  }

  @include respond-to(large) {
    margin-top: 12px;
  }
}

.UserCollection-number {
  @include font-light;

  color: $grey-50;
  font-size: $font-size-default;
  margin: 0;
}
