@import '~amo/css/styles';

.WrongPlatformWarning {
  margin: 0 0 12px;
  padding-left: $padding-page;
  padding-right: $padding-page;

  @include respond-to(large) {
    padding-left: $padding-page-l;
    padding-right: $padding-page-l;
  }
}
