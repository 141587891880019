@import '~amo/css/styles';

$picture-size: 128px;

.UserProfileEditPicture {
  .UserAvatar {
    @include margin-end(24px);

    display: inline-block;
    width: $picture-size;

    .UserAvatar-image {
      margin-bottom: 4px;
    }

    .Icon {
      height: $picture-size;
      width: $picture-size;
    }
  }

  .UserProfileEditPicture-file {
    align-items: stretch;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 4px;
    position: relative;

    .Button {
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      position: relative;
    }
  }

  .UserProfileEditPicture-file--has-focus {
    .Button {
      @include focus;
    }

    // See: https://github.com/WICG/focus-visible
    .UserProfileEditPicture-file-input:not(.focus-visible) + .Button {
      box-shadow: none;
    }
  }

  .UserProfileEditPicture-file-input {
    border: none;
    height: 0.01em !important;
    left: 0;
    margin: 0;
    outline: 0;
    overflow: inherit;
    padding: 0;
    position: absolute;
    top: 0;
    width: 0.01em !important;
  }

  .UserProfileEditPicture-delete-button {
    margin: 24px 0 12px;

    .ConfirmButton-default-button {
      height: auto;
      width: 100%;
    }

    @include respond-to(medium) {
      width: 290px;
    }

    @include respond-to(large) {
      margin-bottom: 0;
    }
  }
}
