@import '~amo/css/styles';

$icon-size: 32px;

@include respond-to(large) {
  .EditableCollectionAddon {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: minmax(($icon-size + 80px), auto) min-content;
  }
}

.EditableCollectionAddon-buttons,
.EditableCollectionAddon-details {
  align-items: center;
  display: flex;
  margin-top: $padding-page;

  @include respond-to(large) {
    display: grid;
    grid-gap: 24px;
    padding: 0;
  }
}

.EditableCollectionAddon-details {
  grid-template-columns: $icon-size minmax(80px, auto);
}

.EditableCollectionAddon-buttons {
  grid-template-columns: min-content min-content;
  justify-content: flex-end;
}

.EditableCollectionAddon-icon {
  @include margin-end(12px);

  display: inline-block;
  height: $icon-size;
  width: $icon-size;

  .EditableCollectionAddon--statictheme & {
    opacity: 0;
  }
}

.EditableCollectionAddon-name {
  @include font-medium;

  font-size: $font-size-default;
  hyphens: auto;
  margin: 0;

  @include respond-to(extraLarge) {
    align-items: center;
    display: flex;
  }
}

div.EditableCollectionAddon-leaveNote {
  @include margin-end(12px);

  @include respond-to(large) {
    align-items: center;
    display: flex;
    margin-top: 0;
  }
}

.EditableCollectionAddon-leaveNote--hidden {
  display: none;
  @include respond-to(large) {
    display: flex;
    visibility: hidden;
  }
}

.EditableCollectionAddon-remove-button,
.EditableCollectionAddon-leaveNote-button {
  overflow: hidden;
  white-space: nowrap;
}

div.EditableCollectionAddon-notes {
  background-color: transparentize($blue-50, 0.95);
  border-radius: $border-radius-default;
  grid-column: 1 / 3;
  margin-top: $padding-page;
  padding: 12px;
  word-break: break-all;

  @include respond-to(large) {
    margin-top: 0;

    @include margin-start(12px);
  }
}

.EditableCollectionAddon-notes-header {
  margin: 0 0 6px;

  .Icon-comments-blue {
    @include margin-end(12px);
  }
}

.EditableCollectionAddon-notes-buttons {
  text-align: right;
}

.EditableCollectionAddon-notes-form {
  margin: 12px 0 0;

  .DismissibleTextForm-buttons {
    margin-top: 12px;
  }
}
