@import '~amo/css/styles';

.Select {
  @include padding-end(24px);
  @include padding-start(6px);

  appearance: none;
  background: $grey-30 url('~amo/components/Icon/img/triangle-down-black.svg')
    no-repeat calc(100% - 10px) 50%;
  background-size: 12px;
  border: 0;
  border-radius: $border-radius-s;
  display: block;
  line-height: $line-height-compressed;
  overflow: hidden;
  padding-bottom: 10px;
  padding-top: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  [dir='rtl'] & {
    background-position: 10px 50%;
  }
}
