@import '~amo/css/styles';

.DefinitionList {
  margin: 0;
  padding: 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.Definition-dt {
  @include font-regular;

  color: $grey-50;
  margin: 0;
  padding: 0;
}

.Definition-dd {
  @include font-medium;

  margin: 0 0 12px;
  padding: 0;
}
