@import '~amo/css/styles';

.Footer {
  @include font-regular;

  background: $footer-base-color;
  color: $white;
  width: 100%;

  a:link,
  a:visited,
  a:hover,
  a:active {
    @include font-regular;

    color: $white;
    font-size: $font-size-l;
    font-weight: 400;
    margin: 0 0 12px;
    text-decoration: none;
  }

  a:active,
  a:hover {
    color: $white;
    text-decoration: underline;
  }
}

.Footer-wrapper {
  padding: 58px 20px;

  @include respond-to(medium) {
    padding: 58px $header-footer-gutter;
  }

  @include respond-to(large) {
    display: grid;
    grid-template-areas:
      'logo amo-links browsers-links product-links'
      'legal-links legal-links legal-links legal-links'
      'copyright copyright copyright language';
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 auto;
    max-width: $max-content-width;
    width: 100%;
  }
}

.Footer-amo-links {
  grid-area: amo-links;
}

.Footer-browsers-links {
  grid-area: browsers-links;
}

.Footer-product-links {
  grid-area: product-links;
}

.Footer-links-header {
  @include font-bold;

  font-size: $font-size-l;
  margin: 40px 0 0;

  @include respond-to(large) {
    margin: 0;
  }

  a:link,
  a:visited {
    @include font-bold;

    margin: 0;
  }
}

.Footer-links {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;

  li {
    display: block;
    margin-top: 10px;
  }

  .Footer-link-social {
    display: inline-block;
    @include margin-end(20px);
  }
}

.Footer-legal-links {
  grid-area: legal-links;
  list-style: none;
  margin-top: 40px;
  padding: 0;

  li {
    display: inline-block;
    @include margin-end(30px);
  }

  a:link {
    font-size: $font-size-s;
  }
}

.Footer-links-social {
  margin-top: 30px;
}

.Footer-copyright {
  font-size: $font-size-s;
  grid-area: copyright;
  @include padding-end(20px);

  a:link,
  a:visited {
    color: $grey-40;
    font-size: $font-size-s;
  }
}

.Footer-mozilla-link-wrapper {
  grid-area: logo;
}

.Footer-mozilla-link {
  display: inline-block;
  margin-top: 10px;

  @include respond-to(medium) {
    margin-bottom: auto;
  }
}

.Footer-language-picker {
  grid-area: language;
  margin-top: 40px;

  @include respond-to(medium) {
    margin-top: 7px;
  }

  .LanguagePicker-header {
    @include margin-end(20px);

    display: inline-block;
    font-size: $font-size-s;
  }

  /* The language picker shows text in a bunch of different locales, use a
     generic sans-serif font to avoid downloading the full Inter variant
     just for this. */

  .LanguagePicker-selector {
    background-color: $white;
    color: $black;
    display: inline-block;
    font-family: sans-serif;
  }
}
