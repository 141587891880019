@import '~amo/css/styles';

.AddonReviewCard {
  .Rating {
    height: $font-size-l;
  }

  &:not(.AddonReviewCard-slim) .UserReview-body {
    margin-bottom: 18px;
  }

  .UserReview-byLine {
    flex-wrap: wrap;
  }

  .DismissibleTextForm-buttons {
    margin-top: 6px;
  }

  .ErrorList .ErrorList-item {
    margin: 0;
    padding: 12px 0;
  }
}

.AddonReviewCard-authorByLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.AddonReviewCard-allControls {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;

  @include respond-to(medium) {
    justify-content: flex-start;
  }

  .AddonReviewCard-control:not(.AddonReviewCard-deleting) {
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $blue-50;
      font-weight: normal;
      text-decoration: underline;
    }
  }
}

.AddonReviewCard-delete {
  background: none;
  border: 0;
  color: $blue-50;
  font-size: $font-size-s;
  height: auto;
  line-height: $line-height-body;
  padding: 0;

  &.Button.Button--neutral:hover {
    background: none;
  }

  &.Button.Button--neutral:active {
    padding-top: 0;
  }

  .AddonReviewCard-ratingOnly.AddonReviewCard-slim & {
    color: $text-color-default;

    &:active,
    &:hover {
      color: $blue-50;
    }
  }
}

.AddonReviewCard-control {
  display: flex;
  max-width: 33%;
  text-align: center;

  &:only-child {
    max-width: 100%;
  }

  // This adjusts the max-width when there are only two items. It selects
  // the element based on its position then selects all siblings.

  /* stylelint-disable max-line-length */

  // https://stackoverflow.com/questions/8720931/can-css-detect-the-number-of-children-an-element-has

  /* stylelint-enable max-line-length */
  &:first-child:nth-last-child(2),
  &:first-child:nth-last-child(2) ~ & {
    max-width: 50%;
  }

  @include respond-to(medium) {
    @include margin-end(24px);
  }

  .Icon-reply-arrow {
    @include margin-end(6px);
  }
}

.AddonReviewCard-reply {
  background-color: transparentize($blue-50, 0.95);
  border-radius: $border-radius-default;
  margin-top: 12px;
  padding: 12px;

  @include respond-to(medium) {
    margin-top: 24px;
    padding: 24px;

    @include margin-start(12px);
  }

  .ShowMoreCard-contents::after {
    background: linear-gradient(
      rgba(255, 255, 255, 0%),
      $showmorecard-gradient-color
    );
  }

  footer {
    background-color: transparent;
  }
}

.AddonReviewCard-non-public-notice {
  margin-bottom: 12px;
}

.AddonReviewCard-writeReviewButton {
  margin-top: 12px;
  width: auto;
}

.AddonReviewCard-slim {
  .AddonReviewCard-control {
    margin: 0;
  }

  .AddonReviewCard-allControls {
    justify-content: space-between;
  }

  &.AddonReviewCard-ratingOnly {
    .AddonReviewCard-allControls {
      justify-content: center;
    }

    .UserReview-byLine {
      display: none;
    }
  }

  .AddonReviewCard-confirmDeleteDialog {
    margin-top: 12px;
    text-align: center;
    width: 100%;

    .ConfirmationDialog-buttons {
      display: block;
    }

    .ConfirmationDialog-cancel-button,
    .ConfirmationDialog-confirm-button {
      font-size: $font-size-default;
      margin: 0;
      width: 100%;
    }

    .ConfirmationDialog-cancel-button {
      margin-top: 12px;
    }
  }

  .AddonReviewCard-writeReviewButton {
    width: 100%;
  }

  .DismissibleTextForm-delete-submit-buttons,
  .DismissibleTextForm-submit {
    width: 100%;
  }

  .DismissibleTextForm-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }

  .DismissibleTextForm-dismiss {
    margin-top: 12px;
    text-align: center;
    width: 100%;
  }

  /* stylelint-disable selector-pseudo-class-parentheses-space-inside */

  // stylelint-disable selector-combinator-space-before
  &.AddonReviewCard-viewOnly:not(.AddonReviewCard-ratingOnly):not(
      .AddonReviewCard-isReply
    )
    > .AddonReviewCard-container {
    background-color: transparentize($blue-50, 0.95);
    border-radius: $border-radius-default;
    padding: 12px;

    @include respond-to(medium) {
      padding: 12px 24px;
    }

    footer {
      background-color: transparent;
    }
  }
}

.AddonReviewCard:not(
    .AddonReviewCard-slim
  ).AddonReviewCard-confirmDeleteDialog {
  flex-flow: column wrap;

  .Button.ConfirmationDialog-cancel-button {
    @include margin-start(6px);
  }

  .Button.ConfirmationDialog-confirm-button {
    @include respond-to(medium) {
      @include margin-start(6px);
    }
  }

  @include respond-to(medium) {
    flex-direction: row;
  }

  &,
  .ConfirmationDialog-buttons {
    align-items: center;
    column-gap: 12px;
    display: flex;
  }

  .ConfirmationDialog-buttons {
    flex-direction: row;

    .Button {
      margin: 0;
    }

    &,
    .Button {
      width: 100%;
    }

    @include respond-to(medium) {
      &,
      .Button {
        width: auto;
      }
    }
  }
}
