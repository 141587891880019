@import '~amo/css/styles';

$cell-padding: 6px;

.LanguageTools {
  @include page-padding;
}

.LanguageTools-table {
  width: 100%;
}

.LanguageTools-header-row {
  background: $grey-30;
}

.LanguageTools-header-cell {
  @include text-align-start;

  font-size: $font-size-l;
}

.LanguageTools-addon-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

// We target tags and some very specific classNames (which we tend to avoid
// doing in our styles) because this needs to override rules set by
// `react-super-responsive-table`, which gives us the awesome responsive
// table used on this page but sets some unfortunate default border/padding
// values.
.LanguageTools-table.responsiveTable {
  tbody tr {
    border: 1px solid $grey-30;
    border-bottom: 0;
    padding: 0;

    &:nth-child(even) {
      background: $grey-20;
    }

    &:last-child {
      border-bottom: 1px solid $grey-30;
    }
  }

  th,
  td {
    border: 0;
    padding: $cell-padding;
  }

  td {
    min-height: $font-size-default + (2 * $cell-padding);
  }
}

.LanguageTool-localeName {
  @include respond-to(large) {
    width: 25%;
  }
}
