@import '~amo/css/styles';

.TooltipMenu {
  border: 0;
  border-radius: $border-radius-default;
  padding: 0 10px;
  width: 100%;

  @include respond-to(medium) {
    padding: 0;
    width: 320px;
  }
}

.TooltipMenu-opener {
  color: $blue-50;
  cursor: pointer;
  line-height: $line-height-body;
  margin: 0;
  padding: 0;
}

// This is the triangle at the top of the tooltip menu which is
// actually just a tilted square.
.TooltipMenu-arrow {
  background-color: $white;
  box-shadow: 0 0 2px transparentize($black, 0.5);
  display: block;
  height: 8px;
  left: 50%;
  position: absolute;
  top: -4px;
  transform: translateX(-50%) rotate(45deg);
  width: 8px;

  // Since it has a box shadow, make sure the element appears underneath
  // TooltipMenu-list.

  z-index: 1;
}

.TooltipMenu-inner {
  border-radius: $border-radius-default;
  box-shadow: 0 0 2px transparentize($black, 0.5);
}

.TooltipMenu-list,
.CardList .TooltipMenu-list {
  background: $white;
  border-radius: $border-radius-default;
  list-style-type: none;
  margin: 0;
  padding: 6px;
  position: relative;

  // Make sure this masks the box shadow of TooltipMenu-arrow.
  z-index: 2;

  .ListItem,
  .CardList .ListItem {
    align-items: center;
    background: none;
    display: flex;
    margin: 0;
    padding: 6px;
    padding-top: 0;

    &:first-child {
      padding-top: 6px;
    }

    &,
    a,
    a:link,
    button {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      width: 100%;

      &:not(.Button) {
        @include text-align-start;

        color: $grey-90;
        font-size: $font-size-default;
        line-height: $line-height-body;
      }
    }

    a,
    a:link,
    button {
      &:not(.Button):not([disabled]) {
        @include font-medium;

        cursor: pointer;
        margin: 0;
        padding: 0;
        text-decoration: none;
      }
    }

    a:hover,
    a:focus,
    a:active {
      &:not(.Button) {
        color: $blue-50;
      }
    }

    @include respond-to(medium) {
      button:hover,
      button:focus {
        color: $blue-50;
      }
    }

    .ErrorList {
      margin-bottom: 6px;
    }

    .ErrorList-item {
      white-space: normal;
    }
  }
}
