@import '~amo/css/styles';

.AddonRecommendations {
  // stylelint-disable max-nesting-depth

  &.AddonsCard--horizontal {
    ul.AddonsCard-list {
      // overriding default Search Results styles
      @include respond-to(large) {
        grid-auto-flow: initial;
        grid-template-columns: repeat(2, 50%);
      }

      .SearchResult {
        @include respond-to(large) {
          padding: 12px 24px;

          &:hover {
            background-color: $grey-10;
            border-radius: $border-radius-default;
            cursor: pointer;

            .SearchResult-name {
              color: $link-color;
            }

            .SearchResult-users {
              display: none;
            }

            .SearchResult-metadata {
              display: block;
            }
          }
        }
      }

      .SearchResult-wrapper {
        @include respond-to(large) {
          display: block;
          padding: 0;

          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
      }

      .SearchResult-users,
      .SearchResult-metadata {
        @include respond-to(large) {
          height: auto;
          min-height: 24px;
        }
      }
    }
  }
}
