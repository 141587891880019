@import '~amo/css/styles';

.ShowMoreCard {
  @include respond-to(large) {
    .Card-contents {
      border-radius: 0 0 $border-radius-default $border-radius-default;
    }
  }
}

.ShowMoreCard-contents {
  &::after {
    // rgba(255, 255, 255, 0%) instead of transparent prevents
    // grey line in Safari.
    // See: https://github.com/mozilla/addons-frontend/issues/2865
    background: linear-gradient(rgba(255, 255, 255, 0%), $base-color);
    bottom: 0;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  max-height: 150px;
  overflow: hidden;
  position: relative;

  .ShowMoreCard--expanded & {
    &::after {
      display: none;
    }

    max-height: none;
  }
}

.AddonDescription,
.Addon-developer-comments {
  .ShowMoreCard-contents {
    @include respond-to(large) {
      &::after {
        height: 0;
      }

      max-height: none;
    }
  }

  .Card-footer-link {
    @include respond-to(large) {
      display: none;
    }
  }
}

.PermissionsCard {
  .Card-contents {
    border-radius: 0;
  }

  .ShowMoreCard-contents {
    max-height: 300px;
  }

  &.ShowMoreCard--expanded .ShowMoreCard-contents {
    max-height: none;
  }
}

.PermissionsCard.ShowMoreCard--expanded {
  .Card-contents {
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
  }
}
