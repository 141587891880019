@import '~amo/css/styles';

.UserReview {
  .Rating {
    margin: 0;

    @include margin-end(6px);
  }

  .ErrorList {
    margin-top: 6px;
  }

  .Card-contents {
    background: transparent;
    padding: 0 0 2px 2px;
  }

  .ShowMoreCard.UserReview-emptyBody {
    margin: 0;
  }

  @include respond-to(medium) {
    .TooltipMenu {
      width: fit-content;
    }
  }
}

.UserReview-review-header {
  margin: 0;
  padding: 0;
}

.UserReview-body.ShowMoreCard {
  font-size: $font-size-default;
  margin: 0;

  &:not(.UserReview-emptyBody) {
    margin: 4px 0 6px;
  }
}

.UserReview-byLine {
  color: $sub-text-color;
  display: flex;
  font-size: $font-size-s;

  a,
  a:link,
  a:visited {
    color: $sub-text-color;
    font-weight: normal;
  }

  a:active,
  a:hover {
    color: $link-color;
  }

  a:focus {
    @include focus;
  }
}

.UserReview-reply-header {
  @include margin-end(6px);

  color: $text-color-default;
  margin-bottom: 6px;
  margin-top: 0;

  .Icon-reply-arrow {
    @include margin-end(12px);
  }
}
