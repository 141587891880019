@import '~amo/css/styles';

.AddonVersions {
  @include page-padding;
}

.AddonVersions-wrapper {
  @include respond-to(large) {
    display: flex;
  }

  .CardList ul {
    li {
      margin-bottom: 0;

      @include respond-to(large) {
        padding-bottom: 0;
      }

      &:last-of-type {
        padding-bottom: $padding-page;
      }

      @include respond-to(large) {
        &:last-of-type {
          padding-bottom: $padding-page-l;
        }
      }
    }

    .AddonVersionCard-releaseNotes ul {
      li {
        list-style-position: inside;
        padding: 0;
      }
    }

    .InstallButtonWrapper-download {
      @include text-align-start;

      @include respond-to(extraLarge) {
        @include text-align-end;
      }
    }
  }
}

.AddonVersions-warning-text {
  display: block;
  margin: 0;
}

.AddonVersions-versions {
  margin-top: $padding-page;

  @include respond-to(large) {
    @include margin-start($padding-page-l);

    margin-top: 0;
    width: 65%;
  }
}
