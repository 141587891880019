@import '~amo/css/styles';

.Search {
  @include respond-to(large) {
    display: grid;
    grid-auto-flow: column dense;
    grid-gap: $padding-page-l;
    grid-template-columns: minmax(300px, 35%) 1fr;
    padding: $padding-page-l;
    padding-top: 0;
  }

  .Card {
    margin: $padding-page;

    @include respond-to(large) {
      margin: 0;
    }
  }

  .SearchSort {
    margin: $padding-page;

    @include respond-to(large) {
      grid-column: 1 / 2;
      grid-row: 2;
      margin: 0;
    }
  }

  .SearchContextCard {
    @include respond-to(large) {
      grid-column: 1 / -1;
      grid-row: 1;

      // Allows the grid item to contain the content preventing overflow.
      min-width: 0;
    }
  }

  .SearchResults {
    @include respond-to(large) {
      grid-column: 2;
      grid-row: 2 / 5;

      // Allows the grid item to contain the content preventing overflow.
      min-width: 0;

      // Hack to force column height calculations in ESR 52.
      // See: https://github.com/mozilla/addons/issues/566

      width: 100%;

      .SearchResult {
        padding: 24px 36px;
      }
    }
  }

  .SearchResults-message {
    background: $base-color;
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
    margin-top: 0;
    padding: 20px;
  }

  .ErrorList {
    margin: 10px 10px 0;

    @include respond-to(large) {
      margin: 0;
    }
  }
}
