@import '~amo/css/styles';

.CollectionDetails {
  .MetadataCard {
    @include respond-to(large) {
      margin-top: 12px;
    }
  }
}

.CollectionDetails-title {
  @include font-light;

  font-size: $font-size-heading-s;
  hyphens: auto;
}

.CollectionDetails-description {
  font-size: $font-size-s;
  hyphens: auto;
}

.CollectionDetails-edit-button,
.CollectionDetails-edit-details-button,
.CollectionDetails-back-to-collection-button {
  margin-top: $padding-page;
  width: 100%;
}
