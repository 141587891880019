/* This file is automatically generated! See src/fonts/README.md for details */
@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 300 600; /* We only need those weights */
  src: url('woff2/Inter-roman.var.woff2') format('woff2');
  unicode-range: U+20-7E, U+A0-148, U+14A-1C3, U+1C5-31E, U+321-362, U+370-377,
    U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3E1, U+3F0-49D, U+4A0-4FF,
    U+52F, U+1D43, U+1D47-1D49, U+1D4D, U+1D4F, U+1D50, U+1D52, U+1D56-1D58,
    U+1D5B, U+1D62-1D65, U+1D9C, U+1DA0, U+1DBB, U+1DBF, U+1E00-1F15,
    U+1F18-1F1D, U+1F20-1F45, U+1F48-1F4D, U+1F50-1F57, U+1F59, U+1F5B, U+1F5D,
    U+1F5F-1F7D, U+1F80-1FB4, U+1FB6-1FC4, U+1FC6-1FD3, U+1FD6-1FDB, U+1FDD-1FEF,
    U+1FF2-1FF4, U+1FF6-1FFE, U+2000-200B, U+2010-2027, U+202F-205F, U+2080,
    U+20A0-20BF, U+2100, U+2101, U+2103, U+2105, U+2106, U+2109, U+2113, U+2116,
    U+2117, U+211E-2123, U+2126, U+212A, U+212B, U+212E, U+2132, U+213B, U+214D,
    U+214F, U+2153, U+215A-215F, U+2190-2193, U+2202, U+2205, U+2206, U+220F,
    U+2211, U+2212, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264, U+2265,
    U+2303, U+2305, U+2318, U+2325-2327, U+232B, U+2380, U+2387, U+238B, U+23CE,
    U+23CF, U+2423, U+2600, U+2605, U+2606, U+263C, U+2661, U+2665, U+26A0,
    U+2713, U+2717, U+27EF, U+27F5-27FA, U+2B06, U+2B12, U+2B13, U+2B1C, U+2C7C,
    U+2C7F, U+2DFF, U+2E18, U+A69F, U+A7FF, U+A92E, U+E000, U+E002-E080,
    U+E093-E096, U+E0A5-E0E6, U+E0F3-E11C, U+E11E-E164, U+EE01, U+F6C3,
    U+FE20-FE2D, U+FEFF;
}
