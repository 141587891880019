.ErrorList {
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
}

.ErrorList-item,
.CardList .ErrorList-item {
  background: none;
}

.ErrorList-item {
  list-style: none;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}
