@import '~amo/css/styles';

.ExpandableCard-ToggleLink {
  display: flex;
  justify-content: space-between;
  text-decoration: none;

  &:link {
    color: $grey-60;
  }

  @include respond-to(large) {
    cursor: default;
  }
}

.ExpandableCard-ToggleArrow {
  @include margin-end(5px);

  transition: all 70ms ease-out;

  .ExpandableCard--expanded & {
    transform: rotateX(180deg);
  }

  @include respond-to(large) {
    display: none;
  }
}

.ExpandableCard {
  .Card-header {
    border-radius: $border-radius-default;

    @include respond-to(large) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .Card-contents {
    display: none;

    @include respond-to(large) {
      display: block;
    }
  }
}

.ExpandableCard--expanded {
  .Card-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .Card-contents {
    display: block;
  }
}
