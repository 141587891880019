@import '~amo/css/styles';

.AddonVersionCard {
  display: grid;
  grid-gap: $padding-page;

  .InstallButtonWrapper {
    grid-column: 1;
    grid-row: 3;
    width: 100%;

    .Button {
      width: 100%;
    }

    @include respond-to(large) {
      align-self: end;
    }

    @include respond-to(extraLarge) {
      grid-column: 2;
      grid-row: 3;
    }
  }

  .InstallWarning {
    grid-row: 2;

    @include respond-to(extraLarge) {
      grid-column: 1 / span 2;
      grid-row: 2;
    }
  }
}

.AddonVersionCard-content {
  grid-column: 1;
}

.AddonVersionCard .AddonVersionCard-header {
  @include font-regular;
  @include text-align-start;

  font-size: $font-size-heading-xs;
}

.AddonVersionCard-version {
  @include font-medium;

  color: $link-color;
}

.AddonVersionCard-fileInfo,
.AddonVersionCard-compatibility {
  color: $grey-50;
}

.AddonVersionCard-releaseNotes {
  font-size: $font-size-default;
  margin-top: $padding-page;

  @include respond-to(medium) {
    margin-top: $padding-page-l;
  }
}

.AddonVersionCard-license {
  color: $grey-50;
}
