@import '~amo/css/styles';

.AddonSuggestions {
  background: $white;
  border-bottom-left-radius: $border-radius-default;
  border-bottom-right-radius: $border-radius-default;
  margin-top: 0;
  min-height: 170px; // Added to avoid shift when suggestions load.
  // stylelint-disable max-nesting-depth

  &.AddonsCard--horizontal {
    .Card-header-text {
      max-width: 100%;

      @include respond-to(large) {
        max-width: 450px;
      }

      @include respond-to(extraLarge) {
        max-width: 100%;
      }
    }

    ul.AddonsCard-list {
      .SearchResult {
        .SearchResult-note {
          display: none;
        }

        @include respond-to(large) {
          padding: 12px 24px;

          &:hover {
            background-color: $grey-10;
            border-radius: $border-radius-default;
            cursor: pointer;

            .SearchResult-name {
              color: $link-color;
            }

            .SearchResult-users {
              display: none;
            }

            .SearchResult-metadata {
              display: block;
            }
          }
        }
      }

      .SearchResult-wrapper {
        @include respond-to(large) {
          display: block;
          padding: 0;

          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
      }

      .SearchResult-users,
      .SearchResult-metadata {
        @include respond-to(large) {
          height: auto;
          min-height: 24px;
        }
      }
    }
  }
}
