@import '~amo/css/styles';

.ThemeImage {
  height: auto;
  max-height: $theme-height-legacy;
  max-width: $theme-width-default;
  order: -1;
  overflow-y: hidden;
  position: relative;
  width: 100%;
}

.ThemeImage-image {
  display: block;
  height: auto;
  max-width: $theme-width-default;
  object-fit: cover;
  object-position: top left;
  width: 100%;
}

.ThemeImage--rounded-corners {
  border-radius: $border-radius-default;

  .ThemeImage-image {
    border-radius: $border-radius-default;
  }
}
