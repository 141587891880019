@import '~amo/css/styles';

.SearchSuggestion {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.SearchSuggestion-name {
  flex: 1;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SearchSuggestion-icon {
  @include margin-end(12px);

  height: 16px;

  // To handle 404 images, image width is very small so alt text distorts UI
  // https://github.com/mozilla/addons-frontend/issues/4009

  overflow: hidden;
  width: 16px;

  .SearchSuggestion--statictheme & {
    opacity: 0;
  }
}

.SearchSuggestion-icon-arrow,
.SearchSuggestion-icon-promoted {
  @include margin-end(2px);
}

.SearchSuggestion-icon-arrow {
  display: none;
  height: 16px;
  width: 16px;
}
