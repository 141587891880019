@import '~amo/css/styles';

.AddonSummaryCard-overallRatingStars {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 0 12px;

  @include respond-to(extraExtraLarge) {
    margin-bottom: 24px;
  }
}

.AddonSummaryCard {
  @include respond-to(large) {
    width: 35%;
  }
}

.AddonSummaryCard-header {
  display: flex;
  justify-content: flex-start;
  padding: 0 0 12px;
}

.AddonSummaryCard-header-icon {
  @include margin-end(12px);

  align-items: center;
  display: flex;
  height: 36px;

  .AddonSummaryCard--statictheme & {
    display: none;
  }
}

.AddonSummaryCard-header-text {
  // See: https://stackoverflow.com/questions/36150458
  min-width: 50%;

  .AddonTitle {
    @include font-bold;

    font-size: $font-size-l;
    line-height: $line-height-body;
    margin: 0;
    padding: 0;

    a,
    a:link {
      color: $link-color;
      text-decoration: none;
    }

    .AddonTitle-author {
      @include font-bold;

      font-size: $font-size-l;
      margin: 0;
      padding: 0;

      a,
      a:link {
        color: $black;
        font-size: $font-size-l;
        text-decoration: underline;
      }
    }
  }
}

.AddonSummaryCard-header-icon-image {
  display: block;
  height: 36px;
}

.AddonSummaryCard-addonAverage {
  color: $grey-50;
  margin-top: 6px;
  text-align: center;
}
