@import '~amo/css/styles';
@import '~amo/components/Icon/vars';

$icon-promoted-large-size: 24px;
$icon-promoted-small-size: 16px;

.IconPromotedBadge {
  box-sizing: border-box;
  display: flex;
  height: auto;
  width: auto;
}

.IconPromotedBadge-large {
  background-size: $icon-promoted-large-size;
  height: $icon-promoted-large-size;
  width: $icon-promoted-large-size;

  &.Icon-line {
    transform: scale(0.9);
  }

  &.Icon-recommended {
    // Nudge the trophy into the center of the circle.
    transform: translate(2px, 0) scale(0.8);

    [dir='rtl'] & {
      transform: translate(-2px, 0) scale(0.8);
    }
  }
}

.IconPromotedBadge-small {
  background-size: $icon-promoted-small-size;
  height: $icon-promoted-small-size;
  width: $icon-promoted-small-size;
}
