@import '~amo/css/styles';

.CollectionManager {
  label {
    display: block;
    font-size: $font-size-s;
    padding: 24px 0 6px;
  }

  .CollectionManager-collectionName {
    padding-top: 0;
  }

  input,
  textarea {
    resize: vertical;
    width: 100%;

    &:focus {
      @include focus;
    }
  }

  textarea {
    min-height: $font-size-default * 3;
  }
}

.CollectionManager-slug {
  // Always render the grid left-to-right. This UI involves a URL so
  // it should always be displayed left-to-right.

  direction: ltr;
  display: grid;
  grid-template-columns: 40% 60%;

  input {
    // Disregarding text direction is intentional.
    border-left: none;
    border-radius: 0 $border-radius-xs $border-radius-xs 0;
    width: auto;
  }
}

.CollectionManager-slug-url-hint {
  background-color: $grey-20;
  border: 1px solid $grey-50;
  border-radius: $border-radius-xs 0 0 $border-radius-xs;

  // Disregarding text direction is intentional.
  border-right: none;
  direction: ltr;
  padding: 4px;
  white-space: nowrap;
  width: auto;
}

.CollectionManager-slug-url-prefix {
  // Switch the direction so that the ellipsis truncates from the left.
  direction: rtl;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CollectionManager-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 36px;
}

.CollectionManager-cancel,
.CollectionManager-submit {
  margin-top: 10px;
  width: 100%;

  @include respond-to(extraExtraLarge) {
    width: auto;
  }
}
