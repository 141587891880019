@import '~amo/css/styles';

.AddonMeta-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.AddonMeta-text,
.AddonMeta-loading-wrapper {
  @include text-align-start;

  margin: 0;
  max-width: 30%;
  padding: 0;
}

.AddonMeta-item-subheader {
  @include font-light;

  display: block;
  margin: 0;
  padding: 0;
}

.AddonMeta-overallRating {
  margin-bottom: 24px;

  .Rating {
    height: 16px;
  }

  .MetadataCard-list {
    max-width: none;
  }
}

.AddonMeta-rating-content,
.AddonMeta-rating-title {
  display: flex;
  justify-content: center;
  text-align: center;
}

.AddonMeta .MetadataCard-content,
.AddonMeta .MetadataCard-title {
  a,
  a:link,
  a:visited {
    color: $grey-50;
    font-weight: normal;
  }

  a.AddonMeta-reviews-content-link {
    &,
    &:link,
    &:visited {
      color: $black;
    }

    &:active,
    &:hover {
      color: $link-color;
    }
  }

  a:active,
  a:hover {
    color: $link-color;
  }

  a:focus {
    @include focus;
  }
}
